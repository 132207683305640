const apiKey = 'AIzaSyCEuWBMAjzADMJpqcYuRWOBvv0ITX7WqLs';

function getGeolocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
        console.error("Geolocation is not supported by this browser.");
        document.body.classList.remove('hidden'); // Show content if geolocation is not supported
        applyDefaultChanges(); // Apply default changes if geolocation fails
    }
}

function successCallback(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`)
        .then(response => response.json())
        .then(data => {
            const result = data.results.find(component =>
                component.types.includes("country")
            );
            if (result) {
                const country = result.address_components[0].short_name;
                applyGeoTaggedChanges(country);
                document.body.classList.remove('hidden'); // Show content after processing
            } else {
                applyDefaultChanges(); // Fallback if no result found
            }
        })
        .catch(error => {
            console.error("Error with Google API:", error);
            applyDefaultChanges(); // Show content even if there's an error
        });
}

function errorCallback(error) {
    console.error("Geolocation Error:", error);
    applyDefaultChanges(); // Show content if there's an error
}

function applyGeoTaggedChanges(country) {
    console.log("COUNTRY CODE", country)
    const middleEasternAndAfricanCountries = ['AE', 'SA', 'EG', 'NG', 'ZA', 'QA', 'KW', 'OM', 'BH', 'DZ', 'MA', 'TN', 'KE', 'GH',];
    const westernCountries = ['US', 'GB', 'FR', 'DE', 'IT', 'IN', 'CA', 'AU', 'PK']; 

    if (middleEasternAndAfricanCountries.includes(country)) {
        applyDubaiChanges(); // Apply changes for Middle Eastern and African countries
    } else if (westernCountries.includes(country)) {
        applyUSChanges(); // Apply changes for Western countries including US, UK, Europe, and Pakistan
    } else {
        applyDefaultChanges(country); // For all other countries
    }
}

function applyDubaiChanges() {
    const solutionsTab = document.querySelector(".menu-list-ul a[href*='solutions']");
    if (solutionsTab) {
        solutionsTab.style.display = "none";
    }

    const homePage = document.querySelector(".menu-list-ul a[href*='index.html']");
    if (homePage) {
        homePage.href = "index.html"; // Update the home page link for Dubai

        // Redirect if the current path is '/index.html'
        if (window.location.pathname.includes('index.html')) {
            window.location.href = "index.html";
        }

        if (window.location.pathname === '/') {
            window.location.href = "index.html";
        }
    }

    // activateSolutionsTab(); // Activate the solutions tab if on the equinox page
}

function applyUSChanges() {
    const solutionsTab = document.querySelector(".menu-list-ul a[href*='solutions']");
    const homePage = document.querySelector(".menu-list-ul a[href*='index'], .menu-list-ul a[href*='index.html']");

    if (homePage) {
        homePage.href = "index.html"; // Set home page link for Western countries

        // Redirect if the current path is '/index.html'
        // if (window.location.pathname.includes('index.html')) {
        //     window.location.href = "index.html";
        // }
    }
    if (solutionsTab) {
        solutionsTab.href = "index.html"; // Update solutions tab for Western countries
    }

    activateSolutionsTab(); // Activate the solutions tab if on the equinox page
}

function applyDefaultChanges(country) {
    // console.log("APPLYING DEFAULT CHANGES ----");

    // const homePage = document.querySelector(".menu-list-ul a[href*='index.html'], .menu-list-ul a[href*='index.html']");
    // if (homePage) {
    //     homePage.href = "index.html"; // Default home page link
    // }

    // const solutionsTab = document.querySelector(".menu-list-ul a[href*='solutions']");
    // if (solutionsTab) {
    //     solutionsTab.style.display = "block"; // Show solutions tab by default
    // }
    // activateSolutionsTab()

    // document.body.classList.remove('hidden'); // Show content

    applyDubaiChanges()
}

// Function to activate the solutions tab if the path is 'index.html'
function activateSolutionsTab() {
    const currentPath = window.location.pathname;
    const solutionsLi = document.querySelector('#bs-example-navbar-collapse-1 > ul > li:nth-child(2) > a').parentElement;
    const homeLi = document.querySelector(".menu-list-ul a[href*='index.html']").parentElement;


    console.log("solutionsLi",solutionsLi)

    if (currentPath.includes('stellaequinox-home') && solutionsLi) {
        solutionsLi.classList.add('active'); // Add the active class to the solutions li
        if(homeLi){
            homeLi.classList.remove('active')
        }
    }
}

// // Trigger the geolocation detection on page load
// document.addEventListener("DOMContentLoaded", function () {
//     applyDubaiChanges()
// });
